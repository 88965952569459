$.fn.hasAttr = function (name) {
	return this.attr(name) !== undefined;
};

// ******* Global Variable s*******

// *** Window
var ww = $(window).width();
var body = $("body");
// *** Header *** //
var header = $("header");
var height_header = header.outerHeight();
var menu_nav = header.find("nav");
var burger = header.find("#burger");
var overlay = $("#overlay");
// *** Nav Moble *** //
var nav_mobile = $(".nav-mobile");
var close_NB = nav_mobile.find("#close-button");
// *** Banner *** //
var pagesBanner = $("#page-banner");
var mainBanner = $("#main-banner");
// *** Footer *** //
$(".search-overlay").removeClass("active");
var mainSection = $("main");
var footer = $("footer");
// *** Component *** //
// Search
var btn_BackToTop = $("#back-to-top");

const headerFunction = {
	onScroll: () => {
		if (pagesBanner.length == 1 || mainBanner.length == 1) {
			var lastScrollTop = 0;
			$(window).on("scroll", function () {
				var st = $(this).scrollTop();
				if (st > height_header * 1.3) {
					header.addClass("header-scroll");
					$(".search-overlay").addClass("header-scroll");
				} else {
					header.removeClass("header-scroll");
					$(".search-overlay").removeClass("header-scroll");
				}
				// removeSearch();
			});
			// On load
			if ($(window).scrollTop() > height_header * 1.3)
				header.addClass("header-scroll");
		} else {
			header.addClass("header-scroll");
		}
	},
	navMobileSlide: () => {
		burger.on("click", function () {
			burger.toggleClass("active");
			nav_mobile.toggleClass("active");
			overlay.toggleClass("active");
			body.toggleClass("ovh");
			header.toggleClass("navMobileOpen");
		});
		close_NB.on("click", function () {
			headerFunction.cancelActiveAllThis();
		});
		overlay.on("click", function () {
			headerFunction.cancelActiveAllThis();
		});
	},
	cancelActiveAllThis: () => {
		burger.removeClass("active");
		nav_mobile.removeClass("active");
		overlay.removeClass("active");
		body.removeClass("ovh");
		header.removeClass("navMobileOpen");
	},
	searchActive: () => {
		//
		$(".search-icon").on("click", function () {
			$(".search-overlay").addClass("active");
			header.addClass("search-overlay-active");
			setTimeout(() => {
				$(".search-overlay .searchbox input").trigger("focus");
			}, 500);
			body.addClass("ovh");
		});
		$(".search-overlay .searchbox input").on("focus", function () {
			$(this).parent().addClass("active");
		});
		$(".search-overlay .searchbox input").on("focusout", function () {
			$(this).parent().removeClass("active");
		});
		$(document).on("click", function (event) {
			var $trigger = $("header");
			var $trigger_2 = $(".search-overlay .searchbox");
			if (
				$trigger !== event.target &&
				!$trigger.has(event.target).length &&
				$trigger_2 !== event.target &&
				!$trigger_2.has(event.target).length
			) {
				$(".search-overlay").removeClass("active");
				header.removeClass("search-overlay-active");
				body.removeClass("ovh");
			}
		});
		$(".search-overlay input").keyup(function () {
			$(".search-overlay").addClass("input-change");
			if ($(this).val() == "") {
				$(".search-overlay").removeClass("input-change");
			}
		});
		// Press ESC to close Search
		setTimeout(() => {
			$(window).on("keyup", function (event) {
				var e = event.keyCode;
				if (e == 27) {
					$(".search-overlay").removeClass("active");
					header.removeClass("search-overlay-active");
					body.removeClass("ovh");
				}
			});
		}, 500);

		// Trigger click with 2 button
		$(".search-overlay .searchbox input[type='text']").keypress(function (
			n
		) {
			if (n.keyCode == 13)
				$(".searchbox button").trigger("click"), console.log("click");
		});
	},
	escBtn: () => {
		$(window).on("keyup", function (event) {
			var e = event.keyCode;
			if (e == 27) {
				nav_mobile.removeClass("active");
				header.removeClass("blur-content");
				burger.removeClass("active");
				overlay.removeClass("active");
				body.removeClass("ovh");
				header.removeClass("navMobileOpen");
			}
		});
	},
	init: () => {
		headerFunction.onScroll();
		headerFunction.navMobileSlide();
		headerFunction.searchActive();
		headerFunction.escBtn();
	},
};

// *** Video Variable
function swiperInit() {
	var main_banner = new Swiper("#main-banner .swiper", {
		spaceBetween: 0,
		autoplay: {
			delay: 4500,
		},
		effect: "creative",
		creativeEffect: {
			prev: {
				shadow: true,
				translate: ["-20%", 0, -1],
			},
			next: {
				translate: ["100%", 0, 0],
			},
		},
		speed: 500,
		observer: true,
		observeParents: true,
		pagination: {
			el: "#main-banner .swiper-pagination",
		},
		navigation: {
			prevEl: "#main-banner .swiper-button-prev",
			nextEl: "#main-banner .swiper-button-next",
		},
	});
	// **** Index **** //
	$(".index-2-slide").each(function (e) {
		$(this).addClass(`index-2-slide-id-${e}`);
		var index_2 = new Swiper(`.index-2-slide-id-${e} .swiper`, {
			breakpoints: {
				1280: {
					slidesPerView: 3,
					spaceBetween: 30,
				},
				1024: {
					slidesPerView: 3,
					spaceBetween: 20,
				},
				576: {
					slidesPerView: 2,
					spaceBetween: 15,
				},
				320: {
					slidesPerView: 1,
					spaceBetween: 15,
				},
			},
			speed: 500,
			observer: true,
			observeParents: true,
			navigation: {
				prevEl: `.index-2-slide-id-${e} .swiper-button-prev`,
				nextEl: `.index-2-slide-id-${e} .swiper-button-next`,
			},
		});
	});
	$(".index-3-slide").each(function (e) {
		$(this).addClass(`index-3-slide-id-${e}`);
		var index_2 = new Swiper(`.index-3-slide-id-${e} .swiper`, {
			breakpoints: {
				1280: {
					slidesPerView: 4,
					spaceBetween: 30,
				},
				1024: {
					slidesPerView: 3,
					spaceBetween: 20,
				},
				576: {
					slidesPerView: 2,
					spaceBetween: 15,
				},
				320: {
					slidesPerView: 1,
					spaceBetween: 15,
				},
			},
			speed: 500,
			observer: true,
			observeParents: true,
			navigation: {
				prevEl: `.index-3-slide-id-${e} .swiper-button-prev`,
				nextEl: `.index-3-slide-id-${e} .swiper-button-next`,
			},
		});
	});

	// **** Introduce **** //
	var introduce_2_slide_thumb = new Swiper(
		".introduce-2-slide .thumb .swiper",
		{
			speed: 500,
			observer: true,
			observeParents: true,
			allowTouchMove: false,
			breakpoints: {
				1280: {
					spaceBetween: 0,
					slidesPerView: 6,
				},
				1024: {
					spaceBetween: 30,
					slidesPerView: 5,
				},
				576: {
					spaceBetween: 15,
					slidesPerView: 5,
				},
				320: {
					slidesPerView: 4,
					spaceBetween: 10,
				},
			},
		}
	);
	var introduce_2_slide_main = new Swiper(
		".introduce-2-slide .main .swiper",
		{
			speed: 500,
			observer: true,
			observeParents: true,
			spaceBetween: 0,
			thumbs: {
				swiper: introduce_2_slide_thumb,
			},
			navigation: {
				prevEl: ".introduce-2-slide .swiper-button-prev",
				nextEl: ".introduce-2-slide .swiper-button-next",
			},
			on: {
				slideChange: function () {
					//
					autoSlideNextOnThumbSlide(
						this,
						".introduce-2-slide",
						".thumb"
					);
				},
			},
		}
	);

	// **** Product **** //
	var linhvuc_detail = new Swiper(".linhvuc-detail .swiper", {
		spaceBetween: 0,
		autoplay: {
			delay: 4500,
		},
		effect: "creative",
		creativeEffect: {
			prev: {
				shadow: true,
				translate: ["-20%", 0, -1],
			},
			next: {
				translate: ["100%", 0, 0],
			},
		},
		speed: 500,
		observer: true,
		observeParents: true,
		pagination: {
			el: ".linhvuc-detail .swiper-pagination",
		},
		navigation: {
			prevEl: ".linhvuc-detail .swiper-button-prev",
			nextEl: ".linhvuc-detail .swiper-button-next",
		},
	});
	var product_other = new Swiper(".linhvuc-other-relative .swiper", {
		speed: 500,
		navigation: {
			prevEl: ".linhvuc-other-relative .swiper-button-prev",
			nextEl: ".linhvuc-other-relative .swiper-button-next",
		},
		breakpoints: {
			1280: {
				slidesPerView: 3,
				spaceBetween: 20,
			},
			1024: {
				slidesPerView: 3,
				spaceBetween: 20,
			},
			576: {
				slidesPerView: 2,
				spaceBetween: 15,
			},
			320: {
				slidesPerView: 1,
				spaceBetween: 15,
			},
		},
	});

	// **** Project **** //
	var project_detail_1_thumb = new Swiper(
		".project-detail-1 .thumb .swiper",
		{
			speed: 500,
			observer: true,
			observeParents: true,
			allowTouchMove: false,
			breakpoints: {
				1280: {
					spaceBetween: 30,
					slidesPerView: 4,
				},
				1024: {
					spaceBetween: 20,
					slidesPerView: 4,
				},
				576: {
					spaceBetween: 10,
					slidesPerView: 4,
				},
				320: {
					slidesPerView: 3,
					spaceBetween: 10,
				},
			},
		}
	);
	var project_detail_1_main = new Swiper(".project-detail-1 .main .swiper", {
		speed: 500,
		observer: true,
		observeParents: true,
		spaceBetween: 0,
		thumbs: {
			swiper: project_detail_1_thumb,
		},
		navigation: {
			prevEl: ".project-detail-1 .swiper-button-prev",
			nextEl: ".project-detail-1 .swiper-button-next",
		},
		on: {
			slideChange: function () {
				//
				autoSlideNextOnThumbSlide(this, ".project-detail-1", ".thumb");
			},
		},
	});
	var project_detail_place = new Swiper(".project-place .swiper", {
		speed: 500,
		observer: true,
		observeParents: true,
		centeredSlides: true,
		// initialSlide: 1,
		loop: true,
		allowTouchMove: false,
		slideToClickedSlide: true,
		breakpoints: {
			1280: {
				spaceBetween: 50,
				slidesPerView: 3,
			},
			1024: {
				spaceBetween: 30,
				slidesPerView: 3,
			},
			576: {
				spaceBetween: 15,
				slidesPerView: 3,
			},
			320: {
				slidesPerView: 3,
				spaceBetween: 10,
			},
		},
		navigation: {
			prevEl: ".project-place .swiper-button-prev",
			nextEl: ".project-place .swiper-button-next",
		},
	});
	var project_detail_2_1 = new Swiper(".pjd-2-slide-1 .swiper", {
		speed: 500,
		observer: true,
		observeParents: true,
		navigation: {
			prevEl: ".pjd-2-slide-1 .swiper-button-prev",
			nextEl: ".pjd-2-slide-1 .swiper-button-next",
		},
		breakpoints: {
			1280: { spaceBetween: 110 },

			320: {
				spaceBetween: 30,
			},
		},
	});
	var project_detail_2_2 = new Swiper(".pjd-2-slide-2 .swiper", {
		spaceBetween: 110,
		speed: 500,
		observer: true,
		observeParents: true,

		navigation: {
			prevEl: ".pjd-2-slide-2 .swiper-button-prev",
			nextEl: ".pjd-2-slide-2 .swiper-button-next",
		},
		breakpoints: {
			1280: {
				slidesPerView: 3,
				spaceBetween: 30,
			},
			1024: {
				slidesPerView: 3,
				spaceBetween: 20,
			},
			576: {
				slidesPerView: 2,
				spaceBetween: 15,
			},
			320: {
				slidesPerView: 1.2,
				spaceBetween: 10,
			},
		},
	});
	$(".pjd-3-slide").each(function (e) {
		$(this).addClass(`pjd-3-slide-id-${e}`);
		var project_detail_3 = new Swiper(`.pjd-3-slide-id-${e} .swiper`, {
			spaceBetween: 0,
			autoplay: {
				delay: 4500,
			},
			effect: "creative",
			creativeEffect: {
				prev: {
					shadow: true,
					translate: ["-20%", 0, -1],
				},
				next: {
					translate: ["100%", 0, 0],
				},
			},
			speed: 500,
			observer: true,
			observeParents: true,
			pagination: {
				el: `.pjd-3-slide-id-${e} .swiper-pagination`,
			},
			navigation: {
				prevEl: `.pjd-3-slide-id-${e} .swiper-button-prev`,
				nextEl: `.pjd-3-slide-id-${e} .swiper-button-next`,
			},
		});
	});
	var project_detail_4 = new Swiper(".pjd-4-slide .swiper", {
		speed: 500,
		observer: true,
		observeParents: true,
		breakpoints: {
			1280: {
				slidesPerView: 3,
				spaceBetween: 30,
			},
			1024: {
				slidesPerView: 3,
				spaceBetween: 20,
			},
			576: {
				slidesPerView: 2,
				spaceBetween: 15,
			},
			320: {
				slidesPerView: 1.2,
				spaceBetween: 10,
			},
		},
		navigation: {
			prevEl: ".pjd-4-slide .swiper-button-prev",
			nextEl: ".pjd-4-slide .swiper-button-next",
		},
	});
}

function autoSlideNextOnThumbSlide(initSwiper, element, thumb) {
	let activeIndex = initSwiper.activeIndex + 1;
	let activeSlide = document.querySelector(
		`${element} ${thumb} .swiper-slide:nth-child(${activeIndex})`
	);
	let nextSlide = document.querySelector(
		`${element} ${thumb} .swiper-slide:nth-child(${activeIndex + 1})`
	);
	let prevSlide = document.querySelector(
		`${element} ${thumb} .swiper-slide:nth-child(${activeIndex - 1})`
	);

	if (nextSlide && !nextSlide.classList.contains("swiper-slide-visible")) {
		initSwiper.thumbs.swiper.slideNext();
	} else if (
		prevSlide &&
		!prevSlide.classList.contains("swiper-slide-visible")
	) {
		initSwiper.thumbs.swiper.slidePrev();
	}
}

function expandItem() {
	// Filter Load
	var hasLoadBtn = $("[has-expand-btn]");
	hasLoadBtn.each(function () {
		var $this = $(this);
		var expandBtn = $(this).find(".expand-btn");
		var list = $(this).find(".expand-item").length;
		var count;
		var countMobile = $(this).attr("item-count-mobile");
		countMobile != undefined && countMobile != 0 && ww < 576
			? (count = Number($(this).attr("item-count-mobile")))
			: (count = Number($(this).attr("item-count")));
		var expand = Number($(this).attr("item-expand"));

		//=// Init
		function init(initValue, thisFunction) {
			if (list >= initValue) {
				thisFunction
					.find(".expand-item")
					.slice(0, initValue)
					.slideDown({
						complete: function () {
							$(this).addClass("done-animated");
						},
					});
			} else {
				thisFunction.find(".expand-item").slideDown();
				thisFunction.find(".expand-item").addClass("done-animated");
			}
			if (list == 0 || list <= initValue) {
				expandBtn.hide();
			}
		}
		init(count, $this);

		// Click
		function expandBtnInit(initCount, thisFunction) {
			count = initCount + expand <= list ? initCount + expand : list;
			thisFunction
				.closest(hasLoadBtn)
				.find(".expand-item")
				.slice(0, count)
				.slideDown({
					complete: function () {
						$(this).addClass("done-animated");
					},
				});
			// console.log(count);
			if (count == list) {
				thisFunction.slideUp();
			}
		}
		expandBtn.on("click", function (e) {
			e.preventDefault();
			expandBtnInit(count, $(this));
		});
	});
}

const cmsFunc = {
	toggleAdminCms: () => {
		function Get_Cookie(check_name) {
			var a_all_cookies = document.cookie.split(";");
			var a_temp_cookie = "";
			var cookie_name = "";
			var cookie_value = "";
			var b_cookie_found = false;
			var i = "";
			for (i = 0; i < a_all_cookies.length; i++) {
				a_temp_cookie = a_all_cookies[i].split("=");
				cookie_name = a_temp_cookie[0].replace(/^\s+|\s+$/g, "");
				if (cookie_name == check_name) {
					b_cookie_found = true;
					if (a_temp_cookie.length > 1) {
						cookie_value = unescape(
							a_temp_cookie[1].replace(/^\s+|\s+$/g, "")
						);
					}
					return cookie_value;
					break;
				}
				a_temp_cookie = null;
				cookie_name = "";
			}
			if (!b_cookie_found) {
				return null;
			}
		}
		function Set_Cookie(name, value, expires, path, domain, secure) {
			var today = new Date();
			today.setTime(today.getTime());
			if (expires) {
				expires = expires * 1000 * 60 * 60 * 24;
			}
			var expires_date = new Date(today.getTime() + expires);
			document.cookie =
				name +
				"=" +
				escape(value) +
				(expires ? ";expires=" + expires_date.toGMTString() : "") +
				(path ? ";path=" + path : "") +
				(domain ? ";domain=" + domain : "") +
				(secure ? ";secure" : "");
		}
		var cmsAdminPanel = document.querySelector(".cmsadminpanel");
		var cmsAdminPanelBtn = document.querySelector(
			".cmsadminpanel .btn-toggle"
		);
		cmsAdminPanelBtn.addEventListener("click", function (e) {
			e.preventDefault();
			if (
				e.target.closest(".cmsadminpanel").classList.contains("active")
			) {
				ShowToolbar();
				Set_Cookie("toolbarOpenState", "open");
			} else {
				HideToolbar();
				Set_Cookie("toolbarOpenState", "closed");
			}
		});
		var openState = Get_Cookie("toolbarOpenState");
		if (openState != null) {
			if (openState == "closed") {
				HideToolbar();
			}
			if (openState == "open") {
				ShowToolbar();
			}
		}

		function HideToolbar() {
			cmsAdminPanel.classList.add("active");
		}

		function ShowToolbar() {
			cmsAdminPanel.classList.remove("active");
		}
	},
	pagination: () => {
		$(".modulepager .pagination")
			.find("a.NextPage, a.LastPage, a.BackPage, a.FirstPage")
			.parent()
			.hide();
	},
	mappingPagination: () => {
		$(".pages.newspager,.pages.productpager").each(function () {
			$(this).prev(".section").append(this);
		});
		if ($(".homepage").length == 1) {
			$(".pages.newspager").remove();
		}
	},
	checkEditMode: () => {
		if ($(".cmsadminpanel").length == 1) {
			if (
				$(".cmsadminpanel .ddviewmode option[value='Edit']").is(
					":selected"
				)
			) {
				$("body").addClass("editmode");
			}
		}
	},
	addClassCustomPage: () => {
		if ($(".news-article").length == 1)
			$("body").addClass("news-detail__page");
	},
	init: () => {
		// cmsFunc.toggleAdminCms();
		cmsFunc.pagination();
		cmsFunc.mappingPagination();
		cmsFunc.checkEditMode();
		cmsFunc.addClassCustomPage();
	},
};

function setHeightEqualToWidth() {
	var widthItem = $("[widthEqualHeight]");
	widthItem.each(function () {
		$(this).css({
			height: $(this).outerWidth(),
		});
	});
}

const globalJS = {
	autoFillNoImage: () => {
		setTimeout(() => {
			$("img").each(function () {
				if ($(this).hasAttr("data-src")) {
					if ($(this).attr("data-src") === "") {
						$(this).attr("data-src", "/no-image.png");
						$(this).attr("src", "/no-image.png");
						$(this).addClass("noImage");
					}
				} else if ($(this).hasAttr("src")) {
					if ($(this).attr("src") === "") {
						$(this).attr("src", "/no-image.png");
						$(this).addClass("noImage");
					}
				}
			});
		}, 150);
	},
	autoAppend: () => {
		var appendId = $("[id*='autoAppend-']");
		appendId.each(function (e) {
			var id = $(this).attr("id").slice(11);
			// console.log(id);
			$(this).appendTo("[id*='autoAppendHere-" + id + "']");
			var curHeight = $(this).get(0).scrollHeight;
		});
	},
	autoClone: () => {
		var cloneId = $("[id*='autoClone-']");
		cloneId.each(function (e) {
			var id = $(this).attr("id").slice(10);
			// console.log(id);
			$(this)
				.clone()
				.appendTo("[id*='autoCloneHere-" + id + "']");
		});
	},
	setBackground: () => {
		var position = ["", "top", "left", "right", "bottom"];
		jQuery.each(position, function (index, item) {
			//
			//
			$(`[setBackground${item}]`).each(function () {
				var background = $(this).attr("setBackground" + item);
				$(this).css({
					"background-size": "cover",
					"background-position": `${item} center`,
					"background-image": `url(${background})`,
				});
			});
		});
	},
	setViewPortBody: () => {
		body.css({
			"max-width": "100vw",
			margin: "0 auto",
			"overflow-x": "hidden",
		});
	},
	scrollToTop: () => {
		$(window).on("scroll", function () {
			if (mainBanner.length == 1 || pagesBanner.length == 1) {
				if (
					$(this).scrollTop() >= mainBanner.outerHeight() ||
					$(this).scrollTop() >= pagesBanner.outerHeight()
				) {
					$("#fixed-tool").addClass("active");
				} else {
					$("#fixed-tool").removeClass("active");
				}
			} else {
				if ($(this).scrollTop() >= height_header * 1.3) {
					$("#fixed-tool").addClass("active");
				} else {
					$("#fixed-tool").removeClass("active");
				}
			}
		});
		$("#scroll-to-top").on("click", function () {
			$("html,body").animate({
				scrollTop: 0,
			});
		});
	},
	spaceWindow: () => {
		var spaceLeft = $("[space-left]");
		var padding = $(".container.max-w-screen-2xl").css("padding-left");
		if (ww >= 1280)
			spaceLeft.each(function (e) {
				$(this).css({
					"margin-left":
						$(".container.max-w-screen-2xl").offset().left +
						parseFloat(padding),
				});
			});
	},
	breadcrumbStyle: () => {
		if (pagesBanner.length == 1) {
			$(".global-breadcrumb").appendTo(pagesBanner.find(".page-banner"));
		} else {
			$(".global-breadcrumb").wrap("<div class='normal-breadcrumb'/>");
		}
	},
	init: () => {
		globalJS.autoFillNoImage();
		globalJS.autoAppend();
		globalJS.autoClone();
		globalJS.setBackground();
		globalJS.setViewPortBody();
		globalJS.scrollToTop();
		globalJS.spaceWindow();
		globalJS.breadcrumbStyle();
	},
};

function counterUpNumber() {
	$(".counter").counterUp({
		delay: 10,
		time: 1000,
	});
}

const handleFormWebSite = {
	changeInputToParent: () => {
		$(".btn.btn-1 input[type='submit'").each(function () {
			$(this).parent().prepend($(this).val());
			$(this).closest(".box-form").attr("form-text", $(this).val());
		});
		$(document).on("click", ".rcRefreshImage", function () {
			setTimeout(() => {
				$(".wrap-form .frm-btn").text($(".box-form").attr("form-text"));
				console.log($(this));
			}, 150);
		});
	},
	init: () => {
		// handleFormWebSite.changeInputToParent();
	},
};

function accordion() {
	var item = $(".accordion-item");
	var title = $(".accordion-title");
	var content = $(".accordion-content");
	var active = $("accordion-item.active");
	title.on("click", function () {
		if ($(this).closest(item).is(".active")) {
			$(this).closest(item).removeClass("active");
			$(this).closest(item).find(content).slideUp();
		} else {
			title.closest(item).removeClass("active");
			title.closest(item).find(content).slideUp();
			$(this).closest(item).addClass("active");
			$(this).closest(item).find(content).slideDown();
		}
	});
	// Onload
	item.eq(0).addClass("active");
	item.eq(0).find(content).slideDown();
}

const pageIntroduce = {
	stickyNav: () => {
		$(".introduce-nav nav").sticky({
			topSpacing: header.outerHeight(),
			zIndex: 100,
			center: true,
		});
	},
	scrollActive: () => {
		if ($(".introduce-nav").length == 1) {
			$(".introduce-nav nav").activescroll({
				scroll: "scroll",
				active: "active",
				offset: height_header + 30,
				animate: 500,
			});
		}
	},
	init: () => {
		pageIntroduce.stickyNav();
		pageIntroduce.scrollActive();
	},
};

const checkClickInside = (element) => {
	$(document).on("click", function (e) {
		if (!$(element).is(e.target) && $(element).has(e.target).length === 0) {
			$(element).removeClass("clickInside");
			$(element).find("ul").slideUp();
		} else {
			$(element).addClass("clickInside");
			$(element).find("ul").slideDown();
		}
	});
};

const pageProject = {
	toggleFilter: () => {
		checkClickInside(".project-type");
	},
	init: () => {
		pageProject.toggleFilter();
	},
};

const pageNews = {
	toggleFilter: () => {
		var item = $(".news-list-nav .nav-mobile");
		$(".news-list-nav .nav-mobile .closeNavNews").on("click", function () {
			$(this).toggleClass("active");
			$(this).closest(".nav-mobile").toggleClass("active");
		});

		$(document).on("click", function (e) {
			if (!item.is(e.target) && item.has(e.target).length === 0) {
				$(".news-list-nav .nav-mobile").removeClass("active");
				$(".news-list-nav .nav-mobile .closeNavNews").removeClass(
					"active"
				);
			}
		});
	},
	init: () => {
		pageNews.toggleFilter();
	},
};

const customTabslet = () => {
	var tabWrapper = $("[custom-tab]");
	var tabLi = tabWrapper.find(".custom-tab li");
	var tabContent = tabWrapper.find(".custom-tabcontent");
	tabWrapper.each(function () {
		$(this)
			.find(".custom-tab li")
			.each(function (e) {
				$(this).attr("tab-index", e + 1);
			});
	});
	tabContent.each(function (e) {
		$(this)
			.find(".tab-content")
			.each(function (n) {
				$(this).attr("tab-content-index", n + 1);
			});
	});
	tabLi.on("click", function () {
		if ($(this).is(".active")) {
			$(this)
				.closest("[custom-tab]")
				.find(".custom-tabcontent")
				.each(function () {
					$(this)
						.find(".tab-content")
						.eq(tabIndex - 1)
						.addClass("active");
					$(this)
						.find(".tab-content")
						.eq(tabIndex - 1)
						.stop();
				});
		} else {
			$(this)
				.closest("[custom-tab]")
				.find(".custom-tab li")
				.removeClass("active");
			$(this).addClass("active");
			var tabIndex = $(this).attr("tab-index");
			$(this)
				.closest("[custom-tab]")
				.find(".tab-content")
				.removeClass("active");
			$(this).closest("[custom-tab]").find(".tab-content").slideUp();
			$(this)
				.closest("[custom-tab]")
				.find(".custom-tabcontent")
				.each(function () {
					$(this)
						.find(".tab-content")
						.eq(tabIndex - 1)
						.addClass("active");
					$(this)
						.find(".tab-content")
						.eq(tabIndex - 1)
						.slideDown();
				});
		}
	});
	//  On load
	tabLi.eq(0).addClass("active");
	tabWrapper.find(".custom-tabcontent").each(function () {
		$(this).find(".tab-content").addClass("hidden");
		$(this).find(".tab-content").eq(0).removeClass("hidden");
		$(this).find(".tab-content").eq(0).addClass("active");
	});
};

function changeInputText() {
	$(".dialogpage .form-group.attachfile1 .ruButton.ruBrowse").val(
		"Upload CV"
	);
}

$(document).ready(function () {
	// ---***--- Global JS ---***---
	globalJS.init();
	cmsFunc.init();

	// ---***--- Header ---***---
	headerFunction.init();

	// ---***--- Body ---***---
	swiperInit();
	counterUpNumber();
	expandItem();
	accordion();
	pageIntroduce.init();
	pageProject.init();
	pageNews.init();

	// ---***--- Custom Tabslet ---***---
	customTabslet();

	// ---***--- Fancybox ---***---
	Fancybox.bind("[data-fancybox]", {});
	Fancybox.bind("a.has-form[data-fancybox]", {
		parentEl: document.forms[0],
	});

	// ---***--- Form ---***---
	handleFormWebSite.init();
	// ---***--- Recruit Form ---***---
	changeInputText();
});

setTimeout(() => {
	console.log(header.outerHeight());
}, 300);
$(window).resize(function () {
	setHeightEqualToWidth();
	globalJS.setViewPortBody();
	globalJS.spaceWindow();
});
$(window).trigger("resize");

// ***** Preload ***** //
document.onreadystatechange = () => {
	if (document.readyState === "complete") {
		/**
		 * Turn on when Deploy
		 */
		header.addClass("remove-loading");
		mainSection.addClass("remove-loading");
		footer.addClass("remove-loading");
	}
};
